var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row no-gutters" },
    [
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", "hide-header": "" },
          model: {
            value: _vm.loadingModal,
            callback: function($$v) {
              _vm.loadingModal = $$v
            },
            expression: "loadingModal"
          }
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("small", [_vm._v("Loading...")])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "loadingModal",
            "hide-footer": "",
            title: "Using Component Methods"
          }
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [_vm._v("Hello From My Modal!")]),
            _vm._v(" "),
            _c("span", { staticClass: "fa fa-spinner fa-spin fa-3x" })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c("b-alert", { attrs: { variant: "danger", show: "" } }, [
            _vm._v("Data Loading Failed")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-2" },
        _vm._l(_vm.routes, function(route) {
          return _c(
            "b-list-group",
            { key: route.start },
            [
              _c(
                "b-list-group-item",
                {
                  staticClass: "flex-column align-items-start",
                  class: { active: _vm.isSelected(route) },
                  attrs: { href: "#", id: route.id },
                  on: {
                    click: function($event) {
                      _vm.showRoute(route)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex w-100 justify-content-between" },
                    [
                      _c("h5", { staticClass: "mb-1" }, [
                        _vm._v(_vm._s(route.title))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(_vm._s(_vm._f("formatDate")(route.start)))
                  ]),
                  _vm._v(" "),
                  _c("small", [_vm._v(_vm._s(route.transport))])
                ]
              )
            ],
            1
          )
        })
      ),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-10" }, [
      _c("div", { attrs: { id: "map-container" } }, [
        _vm._v("\n            map\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }