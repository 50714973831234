var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "view" } },
    [
      _c("navbar", { attrs: { "current-comp": _vm.currentComp } }),
      _vm._v(" "),
      _c(
        "main",
        { attrs: { role: "main" } },
        [_c(_vm.currentComp, { tag: "component" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }