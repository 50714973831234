var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      attrs: { toggleable: "md", type: "dark", variant: "info", id: "navbar" }
    },
    [
      _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              _vm.switchComponent("index")
            }
          }
        },
        [_vm._v("123k.work")]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "nav_collapse" } },
        [
          _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      _vm.switchComponent("wipuMap")
                    }
                  }
                },
                [_vm._v("Map")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      _vm.switchComponent("obelometer")
                    }
                  }
                },
                [_vm._v("Obelometer")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-nav-item-dropdown",
                { attrs: { right: "" } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("em", [_vm._v("Other")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          _vm.switchComponent("about")
                        }
                      }
                    },
                    [_vm._v("About")]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }