var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-2",
              staticStyle: { "max-width": "20rem" },
              attrs: {
                title: "123k.work",
                "img-src": require("../img/img_1670.jpg"),
                "img-alt": "Image",
                "img-top": "",
                tag: "article"
              }
            },
            [
              _c("p", { staticClass: "card-text" }, [
                _vm._v(
                  "\n                    My static homepage. I use it for frontend experiments. Nice that you came along although\n                    you most probably won't find something useful here.\n                "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-2",
              staticStyle: { "max-width": "20rem" },
              attrs: {
                title: "123k.org",
                "img-src": require("../img/img_1834.jpg"),
                "img-alt": "Image",
                "img-top": "",
                tag: "article"
              }
            },
            [
              _c("p", { staticClass: "card-text" }, [
                _vm._v(
                  "\n                    My very personal bike-track homepage. I already use it for several years\n                    and still like to improve it during the winter season. Also with flickr photos!\n                "
                )
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { href: "https://123k.org", variant: "primary" } },
                [_vm._v("Visit")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-2",
              staticStyle: { "max-width": "20rem" },
              attrs: {
                title: "123k.pl",
                "img-src": require("../img/img_2340.jpg"),
                "img-alt": "Image",
                "img-top": "",
                tag: "article"
              }
            },
            [
              _c("p", { staticClass: "card-text" }, [
                _vm._v(
                  "\n                    My personal blog where I write about the development of the 123k projects\n                "
                )
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { href: "https://123k.pl", variant: "primary" } },
                [_vm._v("Visit")]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }