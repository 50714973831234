<template>
    <div class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
            <img src="../img/wazka.jpg" alt="">
        </div>
        <div class="col-md-2">
        </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>