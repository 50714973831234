var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-5" }, [
      _c("canvas", {
        ref: "obelometer-canvas",
        attrs: { width: "500", height: "700" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-7" }, [
      _c("div", { staticClass: "obelometer-display" }, [
        _c("h2", [_vm._v("Office Temperature")]),
        _vm._v(" "),
        _c("span", { staticClass: "temp-display" }, [
          _vm._v(_vm._s(_vm.tempMsg.temperature.value) + "°")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "time-display" }, [
          _vm._v(_vm._s(_vm._f("formatDate")(_vm.tempMsg.time)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }